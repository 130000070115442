import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { EmployeesInfo } from './type';

const useEmployeesForTable = () => {
  const { data, refetch } = useQuery('employees_index', async () => {
    const data = await axiosInstance().get(`/api/v1/settings/employees_index`);
    return data;
  });
  const employeesInfo = humps.camelizeKeys(
    data?.data.employees_info
  ) as unknown as EmployeesInfo;
  return { employeesInfo, refetch };
};

export default useEmployeesForTable;
