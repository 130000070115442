import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.beige};
  padding: 16px 80px;
  height: 100vh;
`;

export const HeadContainer = styled.div`
  width: 100%;
  height: 70px;
  padding: 1.15rem 1.15rem 1.15rem 20px;
  align-items: center;
  justify-content: space-between;
`;

export const CompanyInfo = styled.div`
  display: flex;
  padding: 1rem;
  > span {
    font-weight: 700;
    width: 150px;
  }
`;
