import styled from 'styled-components';

export const TableWrapper = styled.div`
  height: calc(100vh - 169px);
  overflow: auto;
  width: 100vw;
`;
export const Stable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Sthead = styled.thead`
  background: ${(props) => props.theme.palette.beige};
  height: 44px;
  border-bottom: 1px solid ${(props) => props.theme.palette.sand};
  position: sticky;
  top: 0;
  left: 0;
`;

export const TbodyHeader = styled.tr`
  background: #f0f4ff;
  height: 45px;
  border-bottom: 1px solid ${(props) => props.theme.palette.sand};
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
`;

export const Tr = styled.tr<{
  expand?: boolean;
}>`
  background: #fefefe;
  height: 45px;
  border-bottom: 1px solid ${(props) => props.theme.palette.sand};
  display: ${(props) => (props.expand ? '' : 'none')};
  font-size: 0.875rem;
  :last-child {
    border-bottom: 1px solid ${(props) => props.theme.palette.blue};
  }
`;

export const TrOfRemarks = styled.tr<{
  maxWidth: string;
}>`
  background: #fefefe;
  height: 45px;
  border-bottom: 1px solid ${(props) => props.theme.palette.sand};
  font-size: 0.875rem;
  :last-child {
    border-bottom: 1px solid ${(props) => props.theme.palette.blue};
  }
  &.remarks-popup-hidden {
    display: none;
  }
  td {
    &.remarks {
      padding: 0.5rem;
      word-break: break-all;
      max-width: ${(props) => (props.maxWidth ? props.maxWidth : '1340px')};
    }
    div {
      &.remarks-ellipsis {
        max-width: 10ch;
        max-height: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const Td = styled.td<{
  alignRight?: boolean;
}>`
  text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
  vertical-align: middle;
  padding: 0.5rem;
  &.shipping-date {
    width: 90px;
  }
`;

export const Th = styled.th`
  vertical-align: middle;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.5rem;
  &.edit {
    width: 48px;
  }
  &.product {
    min-width: 168px;
  }
  &.shipping-date,
  &.entry-date {
    width: 106px;
  }
  &.amount {
    width: 90px;
  }
`;

export const EntryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  > span {
    color: ${(props) => props.theme.palette.blue};
    cursor: pointer;
  }
`;

export const TbodyTh = styled.th<{
  alignRight?: boolean;
  fontSize?: string;
}>`
  vertical-align: middle;
  text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
  padding: 0.5rem;
  > span {
    font-size: 0.75rem;
  }
  &.amount {
    width: 90px;
  }
  &.shipping-date {
    width: 106px;
  }
  &.comment {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.remarks-popup {
    width: 100vw;
    vertical-align: middle;
    text-align: left;
  }
`;

export const PencilDeleteIcon = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const DatePickerCustom = styled.div`
  .SingleDatePickerInput {
    border: none;
    width: 95px !important;
    height: 20px;
    display: block;
    margin: 0;
    background-color: ${(props) => props.theme.palette.beige};

    > button {
      z-index: 1;
    }
  }
  .DateInput_input {
    display: none;
  }
  .DateInput__small {
    padding-top: 9px;
  }
  .SingleDatePickerInput_calendarIcon {
    position: absolute;
    top: 0%;
    right: -4%;
    padding: 0;
    margin-left: 10px;
    background-color: ${(props) => props.theme.palette.beige};
  }
  .SingleDatePicker_picker {
    z-index: 10 !important;
  }
  .SingleDatePickerInput {
    width: 100px;
  }
  .DayPicker {
    position: absolute;
    top: -38px;
    left: 0px;
  }
  .DateInput_fang {
    display: none;
  }
  .CalendarMonthGrid_1 {
    width: 300px !important;
  }
`;

export const Date = styled.div<{
  date: Date | null;
}>`
  color: ${(props) => (props.date ? props.theme.palette.blue : '')};
  align-items: center;
  padding: 4px 4px 0 5px;
  user-select: none;
  display: flex;
  gap: 5px;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const DeleteModalWrapper = styled.div<{
  width?: string;
}>`
  background: ${(props) => props.theme.palette.white};
  position: fixed;
  left: 50%;
  top: 50%;
  width: ${(props) => (props.width ? props.width : '')};
  transform: translate(-50%, -50%);
  z-index: 10001;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06),
    0px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
`;

export const DeleteModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1.13rem;
  line-height: 27px;
`;

export const DeleteModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  button {
    width: 30%;
    height: 2.75rem;
    &:nth-child(2) {
      border-color: ${(props) => props.theme.palette.error};
      color: ${(props) => props.theme.palette.error};
    }
  }
`;
