import { ManufactureInfoType } from '@lib/pc/settings/manufacture_setting/type';
import * as S from './ManufactureInfo.styles';

type Props = {
  manufactureInfo: ManufactureInfoType;
};

const ManufactureInfo = ({ manufactureInfo }: Props) => {
  return (
    <S.Wrapper>
      <S.HeadContainer className="head-container">
        <S.CompanyInfo>
          <span>会社名</span>
          {manufactureInfo?.manufactureName}
        </S.CompanyInfo>
        <S.CompanyInfo>
          <span>郵便番号</span>
          {manufactureInfo?.manufactureZipCode}
        </S.CompanyInfo>
        <S.CompanyInfo>
          <span>住所１</span>
          {manufactureInfo?.manufactureAddress1}
        </S.CompanyInfo>
        <S.CompanyInfo>
          <span>住所２</span>
          {manufactureInfo?.manufactureAddress2}
        </S.CompanyInfo>
        <S.CompanyInfo>
          <span>電話番号</span>
          {manufactureInfo?.manufacturePhoneNumber}
        </S.CompanyInfo>
      </S.HeadContainer>
    </S.Wrapper>
  );
};

export default ManufactureInfo;
