import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import * as S from './SalesManagementFormModal.styles';
import { ReactComponent as Add } from '@assets/icons/add.svg';
import { ReactComponent as ErrorAttention } from '@assets/icons/error_attention.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/close_24px.svg';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import { WithHeader } from '@components/templates/WithHeader';
import { Heading } from '@components/elements/typographies/Heading';
import { Button } from '@components/elements/buttons/Button';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import useCreateSalesInformationMutation from '@lib/pc/sales_information/useCreateSalesInformationMutation';
import { Todo } from '@lib/common/type';
import useProducts from '@lib/pc/common/hooks/useProducts';
import useEmployees from '@lib/pc/common/hooks/useEmployees';
import useCustomers from '@lib/pc/common/hooks/useCustomers';
import useSalesStores from '@lib/pc/common/hooks/useSalesStores';
import useUpdateSalesInformationMutation from '@lib/pc/sales_information/useUpdateSalesInformationMutation';
import { SalesInformationState } from '@lib/pc/sales_information/type';

const INITIAL_DETAIL = {
  productId: null,
  deliveryDate: null,
  shippingDate: null,
  salesStoreId: null,
  primaryEstimatedAmount: null,
  secondaryEstimatedAmount: null,
  fixedAmount: null,
  errors: null,
};

const toReactSelectOptions = (options: { id: number; name: string }[]) =>
  options.map(({ id, name }) => ({ value: id, label: name }));

type SelectProps = {
  width: string;
  options: { id: number; name: string }[];
  value: number | null;
  disabled?: boolean;
  error?: boolean;
  onChange: (value: number) => void;
  checkCustomerSelectedOrNot?: () => void;
};
const Select = ({
  width,
  options,
  value,
  disabled,
  error,
  onChange,
  checkCustomerSelectedOrNot,
}: SelectProps) => {
  const reactSelectOptions = toReactSelectOptions(options);
  const currentValue = reactSelectOptions.find((o) => o.value === value);

  return (
    <ReactSelect
      options={reactSelectOptions}
      value={currentValue ? currentValue : null}
      onChange={(option) => option && onChange(option.value)}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          width: width,
          height: '40px',
          borderRadius: '0.5rem',
          borderWidth: 0,
          outline: 'solid 1px rgba(0, 0, 0, 0.08)',
          backgroundColor: disabled ? '' : 'white',
          border: error ? `1px solid #C74141` : '',
        }),
      }}
      components={{
        DropdownIndicator: () => <S.SelectIndicator />,
        IndicatorSeparator: () => null,
      }}
      isSearchable={false}
      isDisabled={disabled}
      onMenuOpen={() =>
        checkCustomerSelectedOrNot && checkCustomerSelectedOrNot()
      }
      placeholder="選択してください"
    />
  );
};

type Props = {
  salesInformation: Todo;
  refetch?: () => void;
};

const SalesManagementFormModal = ({ salesInformation, refetch }: Props) => {
  const { state } = useLocation<SalesInformationState>();
  const history = useHistory();
  const [form, setForm] = useState(salesInformation);
  const [submitted, setSubmitted] = useState(false);
  const [detailParams, setDetailParams] = useState<Todo[]>([INITIAL_DETAIL]);
  const [errMsg, setErrMsg] = useState('');
  const [confirmMsg, setConfirmMsg] = useState('');

  const [isChanged, setIsChanged] = useState(false);
  const customerIdError = !form?.customerId;
  const employeeIdError = !form?.employeeId;
  const entryDateError = !form?.entryDate;
  const titleError = !form?.title;

  const { data: products } = useProducts();
  const { data: employees } = useEmployees('sales_managements');
  const { data: customers } = useCustomers();
  const { salesStores, refetch: salesStoresRefetch } = useSalesStores(
    form?.customerId ? form?.customerId : customers && customers[0]?.id
  );

  const [selectedSalesStore, setSelectedSalesStore] = useState(false);

  const detailErrors = form?.details?.map((d: Todo) => {
    return {
      deliveryDate: !d.deliveryDate,
      shippingDate: !d.shippingDate,
      productId: !d.productId,
      primaryEstimatedAmount:
        !!d.primaryEstimatedAmount && isNaN(d.primaryEstimatedAmount),
      secondaryEstimatedAmount:
        !!d.secondaryEstimatedAmount && isNaN(d.secondaryEstimatedAmount),
      fixedAmount: !!d.fixedAmount && isNaN(d.fixedAmount),
    };
  });
  const hasDetailsError = detailErrors?.some((error: Todo) =>
    Object.values(error).includes(true)
  );
  const hasError =
    entryDateError || employeeIdError || titleError || hasDetailsError;
  const handleChangeFormIdValue = (key: string) => {
    return (value: number) => {
      setForm({
        ...form,
        [key]: value,
      });
      setIsChanged(true);
    };
  };
  const handleChangeFormStringValue = (key: string) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setForm({
        ...form,
        [key]: e.target.value,
      });
      setIsChanged(true);
      setDetailParams(detailParams);
    };
  };

  const handleChangeDetailStringValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
    index: number
  ) => {
    const newDetail = { ...form?.details[index], [key]: e.target.value };
    const details = form?.details.map((d: Todo, i: number) =>
      i === index ? newDetail : d
    );
    setForm({ ...form, details });
    setDetailParams(details);
    setIsChanged(true);
  };

  // 新規作成時、製品と販売店舗
  const handleChangeDetailIdValue = (key: string, index: number) => {
    return (value: number) => {
      const newDetail = { ...form?.details[index], [key]: value };
      const details = form?.details.map((d: Todo, i: number) =>
        i === index ? newDetail : d
      );
      setForm({ ...form, details });
      setDetailParams(details);
      setIsChanged(true);
    };
  };

  // 新規作成時は_destroy: true,ではなく、detailParamsから削除する
  const handleClickDeleteDetail = (index: number) => {
    return () => {
      // 更新時の処理
      if (form.id) {
        const newDetail = {
          ...form?.details[index],
          salesStoreId: null,
          _destroy: true,
        };
        const details = form?.details.map((d: Todo, i: number) =>
          i === index ? newDetail : d
        );
        setForm({ ...form, details });
        setDetailParams(details);
      } else {
        detailParams.splice(index, 1);
        setForm({ ...form, details: detailParams });
      }
      setIsChanged(true);
      checkCustomerSelectedOrNot();
    };
  };

  const checkCustomerSelectedOrNot = () => {
    const salesStoreIds = detailParams
      .filter((item) => item._destroy !== true)
      .map(({ salesStoreId }) => salesStoreId);
    const selectedSalesStore = salesStoreIds.some(
      (item) => item !== undefined && item !== null
    );
    setSelectedSalesStore(selectedSalesStore);
  };

  const handleSuccess = () => {
    refetch && refetch();
    setIsChanged(false);
    history.push({
      pathname: '/pc/sales_information',
      state: { ...state, stateMsgKind: 'update' },
    });
  };

  const handleCreateSuccess = () => {
    history.push({
      pathname: '/pc/sales_information',
      state: { ...state, stateMsgKind: 'create' },
    });
  };

  const createSalesInformationMutation = useCreateSalesInformationMutation(
    handleCreateSuccess,
    setErrMsg
  );

  const updateSalesInformationMutation = useUpdateSalesInformationMutation(
    form?.id,
    handleSuccess,
    setErrMsg
  );

  const handleSubmit = () => {
    setSubmitted(true);
    if (form?.id) {
      // 更新処理
      !hasError && updateSalesInformationMutation.mutate(form);
    } else {
      // 新規作成処理
      !hasError && createSalesInformationMutation.mutate(form);
    }
  };

  useEffect(() => {
    if (salesInformation) {
      setForm({
        ...form,
        id: salesInformation.id,
        customerId: salesInformation.customer.id,
        employeeId: salesInformation.employee.id,
        entryDate: salesInformation.entryDate,
        title: salesInformation.title,
        comment: salesInformation.comment,
        details: isChanged ? detailParams : salesInformation.details,
        _destroy: false,
        errors: null,
      });
    }
  }, [salesInformation]);

  useEffect(() => {
    salesStoresRefetch();
  }, [form?.customerId]);

  // 販売店が選択されたら得意先を選択不可にする
  useEffect(() => {
    const hasAnySalesStoreId = detailParams.some(
      (param) => param.salesStoreId !== undefined && param.salesStoreId !== null
    );
    setSelectedSalesStore(hasAnySalesStoreId);
  }, [detailParams]);

  // 新規作成時、削除されたときに販売店選択済みかをチェック→得先のdisabledへ設定
  useEffect(() => {
    checkCustomerSelectedOrNot();
  }, [detailParams.length]);

  if (!products || !employees || !customers || !salesStores) return null;

  return (
    <WithHeader>
      <S.Wrapper>
        <S.HeadContainer>
          <S.FlexBaseline>
            <Heading tag="span" text="営業情報" fs="32" fw="lg" />
            {form?.id ? (
              <Heading tag="span" text="を入力" fs="24" fw="lg" />
            ) : (
              <Heading tag="span" text="を登録" fs="24" fw="lg" />
            )}
          </S.FlexBaseline>
        </S.HeadContainer>
        <S.MainContainer>
          <S.MandatoryItems>
            {hasError && submitted && (
              <S.RequiredError>
                <S.ErrorStatement>
                  <ErrorAttention />
                  必須項目を入力してください
                </S.ErrorStatement>
              </S.RequiredError>
            )}
            <S.InformationInput>
              <S.Label>
                <S.LabelText>記入日</S.LabelText>
                <S.InputDate
                  type="date"
                  id="datepicker"
                  modifiable={salesInformation?.id ? false : true}
                  className="entry-date"
                  value={form?.entryDate}
                  disabled={salesInformation?.id}
                  onChange={handleChangeFormStringValue('entryDate')}
                  error={entryDateError && submitted}
                ></S.InputDate>
              </S.Label>

              <S.Label>
                <S.LabelText>得意先</S.LabelText>
                {salesInformation?.id ? (
                  <S.Contents>{salesInformation?.customer?.name}</S.Contents>
                ) : (
                  <Select
                    width="26rem"
                    options={customers}
                    value={form?.customerId}
                    disabled={selectedSalesStore}
                    onChange={handleChangeFormIdValue('customerId')}
                    error={customerIdError && submitted}
                  />
                )}
              </S.Label>
              <S.Label>
                <S.LabelText>担当者</S.LabelText>
                <Select
                  width="18rem"
                  options={employees}
                  value={form?.employeeId}
                  disabled={salesInformation?.id}
                  onChange={handleChangeFormIdValue('employeeId')}
                  error={employeeIdError && submitted}
                />
              </S.Label>
            </S.InformationInput>
            <div>
              <S.Label>
                <S.LabelText>件名</S.LabelText>
                <S.InputTitle
                  type="text"
                  placeholder="例：○○スーパー 12月分 特売"
                  value={form?.title ? form?.title : ''}
                  onChange={handleChangeFormStringValue('title')}
                  error={titleError && submitted}
                ></S.InputTitle>
              </S.Label>
            </div>
          </S.MandatoryItems>
          <S.ProductAddition>
            <S.AdditionWrapper>
              <S.ProductItems width="15.875rem">商品</S.ProductItems>
              <S.ProductItems width="9.125rem">納品日</S.ProductItems>
              <S.ProductItems width="9.125rem">出荷予定日</S.ProductItems>
              <S.ProductItems width="9.125rem">販売店舗</S.ProductItems>
              <S.ProductItems width="8.625rem">1次見込数量</S.ProductItems>
              <S.ProductItems width="8.625rem">2次見込数量</S.ProductItems>
              <S.ProductItems width="8.625rem">確定数量</S.ProductItems>
            </S.AdditionWrapper>
            {form?.details &&
              form?.details?.map((detail: Todo, index: number) => {
                const error = detailErrors[index];
                return (
                  <S.AdditionRowWrapper key={index} deleted={detail?._destroy}>
                    <S.CloseButtonWrapper>
                      <S.CloseButton onClick={handleClickDeleteDetail(index)}>
                        <CloseIcon />
                      </S.CloseButton>
                    </S.CloseButtonWrapper>
                    <S.AdditionRowFormWrapper>
                      {/* 製品 */}
                      <Select
                        width="15.875rem"
                        options={products}
                        value={detail?.productId}
                        onChange={handleChangeDetailIdValue('productId', index)}
                        error={error?.productId && submitted}
                      />
                      <S.InputContainer width="9.125rem">
                        <S.InputDate
                          type="date"
                          id="datepicker"
                          value={detail.deliveryDate ? detail.deliveryDate : ''}
                          onChange={(e) => {
                            handleChangeDetailStringValue(
                              e,
                              'deliveryDate',
                              index
                            );
                          }}
                          error={error?.deliveryDate && submitted}
                        />
                      </S.InputContainer>
                      <S.InputContainer width="9.125rem">
                        <S.InputDate
                          type="date"
                          id="datepicker"
                          value={detail.shippingDate ? detail.shippingDate : ''}
                          onChange={(e) => {
                            handleChangeDetailStringValue(
                              e,
                              'shippingDate',
                              index
                            );
                          }}
                          error={error?.shippingDate && submitted}
                        ></S.InputDate>
                      </S.InputContainer>
                      {/* 販売店舗 */}
                      <Select
                        width="9.125rem"
                        options={form?.customerId ? salesStores : []}
                        value={detail?.salesStoreId}
                        onChange={handleChangeDetailIdValue(
                          'salesStoreId',
                          index
                        )}
                        checkCustomerSelectedOrNot={checkCustomerSelectedOrNot}
                      />
                      <S.InputWithUnitContainer width="8.625rem">
                        <S.InputTitle
                          value={
                            detail.primaryEstimatedAmount
                              ? detail.primaryEstimatedAmount
                              : ''
                          }
                          onChange={(e) => {
                            if (e.target.value.match(/^[0-9]*$/)) {
                              handleChangeDetailStringValue(
                                e,
                                'primaryEstimatedAmount',
                                index
                              );
                            }
                          }}
                          error={error?.primaryEstimatedAmount && submitted}
                        />
                        <S.Unit>個</S.Unit>
                      </S.InputWithUnitContainer>
                      <S.InputWithUnitContainer width="8.625rem">
                        <S.InputTitle
                          value={
                            detail.secondaryEstimatedAmount
                              ? detail.secondaryEstimatedAmount
                              : ''
                          }
                          onChange={(e) => {
                            if (e.target.value.match(/^[0-9]*$/)) {
                              handleChangeDetailStringValue(
                                e,
                                'secondaryEstimatedAmount',
                                index
                              );
                            }
                          }}
                          error={error?.secondaryEstimatedAmount && submitted}
                        />
                        <S.Unit>個</S.Unit>
                      </S.InputWithUnitContainer>
                      <S.InputWithUnitContainer width="8.625rem">
                        <S.InputTitle
                          value={detail.fixedAmount ? detail.fixedAmount : ''}
                          onChange={(e) => {
                            if (e.target.value.match(/^[0-9]*$/)) {
                              handleChangeDetailStringValue(
                                e,
                                'fixedAmount',
                                index
                              );
                            }
                          }}
                          error={error?.fixedAmount && submitted}
                        />
                        <S.Unit>個</S.Unit>
                      </S.InputWithUnitContainer>
                    </S.AdditionRowFormWrapper>
                  </S.AdditionRowWrapper>
                );
              })}
            <S.ProductAdditionButton>
              <Button
                onClick={() => {
                  setForm({
                    ...form,
                    details: [...form.details, INITIAL_DETAIL],
                  });
                  detailParams.push(INITIAL_DETAIL);
                }}
                padding="lg"
                icon={<Add />}
                outlined={true}
              >
                商品追加
              </Button>
            </S.ProductAdditionButton>
          </S.ProductAddition>
          <S.RemarksWrapper>
            <S.LabelText>備考</S.LabelText>
            <S.Remarks
              onChange={handleChangeFormStringValue('comment')}
              value={form?.comment}
            ></S.Remarks>
          </S.RemarksWrapper>
        </S.MainContainer>
        <S.ButtonWrapper>
          <S.ButtonContainer>
            <Button
              borderWidth={1}
              outlined={true}
              onClick={() => {
                if (isChanged) {
                  setConfirmMsg(
                    '変更内容が保存されていませんが、よろしいですか？'
                  );
                } else {
                  history.push({ pathname: '/pc/sales_information', state });
                }
              }}
            >
              キャンセル
            </Button>
            <Button onClick={handleSubmit} disabled={!isChanged}>
              {form?.id ? '保存' : '登録'}
            </Button>
          </S.ButtonContainer>
        </S.ButtonWrapper>
      </S.Wrapper>
      {errMsg && (
        <ErrorMsgPopUp
          errMsg={errMsg}
          handleClose={() => setErrMsg('')}
          fromPc={true}
        />
      )}
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        handleOk={() =>
          history.push({ pathname: `/pc/sales_information`, state })
        }
        handleCancel={() => setConfirmMsg('')}
      />
      {(confirmMsg || errMsg) && <Overlay dark zIndex={1} />}
    </WithHeader>
  );
};

export default SalesManagementFormModal;
