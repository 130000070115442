import { useMutation } from 'react-query';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';

const useDeleteSalesInformationMutation = (
  id: number | '',
  handleDeleteSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async () => {
      return await axiosInstance().delete(`/api/v1/sales_informations/${id}`);
    },
    {
      onSuccess: () => {
        handleDeleteSuccess();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message);
      },
    }
  );
};

export default useDeleteSalesInformationMutation;
