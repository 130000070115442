import { FC } from 'react';
import * as S from './MaterialAllocationTransferSelect.styles';
import { Storeroom, Todo } from '@lib/common/type';
import ReactSelect from 'react-select';
import { NO_OPTION_JAPANESE } from '@lib/pc/common/constants';

type Props = {
  data: Storeroom[] | undefined;
  value: number | null;
  width?: string;
  height?: string;
  marginTop?: string;
  handleChangeTransfer: (id: number) => void;
  disabled?: boolean;
  menuMarginTop?: string;
};

const MaterialAllocationTransferSelect: FC<Props> = ({
  data,
  value,
  width,
  height,
  marginTop,
  handleChangeTransfer,
  disabled,
  menuMarginTop,
}: Props) => {
  const toReactSelectOptions = (options: { id: number; name: string }[]) =>
    options.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const transferOptions = [
    { id: 0, name: '廃棄' },
    { id: 2, name: '消費' }, // id: 1 = '移動'
  ];

  const reactSelectOptions =
    data && toReactSelectOptions(transferOptions.concat(data));

  const currentValue = reactSelectOptions?.find((o) => o.value === value);

  const styles = {
    control: (baseStyles: Todo) => ({
      ...baseStyles,
      width: width ? width : '237px',
      height: height ? height : '56px',
      marginTop: marginTop ? marginTop : '',
      borderRadius: '0.5rem',
      fontWeight: 400,
      textAlign: 'left',
      borderStyle: 'hidden',
      outline: '0.5px solid rgba(0,0,0,0.08)',
      backgroundColor: disabled ? '' : 'white',
      cursor: 'pointer',
      '@media (max-width: 599px)': {
        width: '16rem',
      },
    }),
    menu: (baseStyles: Todo) => ({
      ...baseStyles,
      top: '37px',
      zIndex: 1002,
      marginTop: menuMarginTop ? menuMarginTop : '',
    }),
    option: (baseStyles: Todo) => ({
      ...baseStyles,
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '15px',
    }),
    menuList: (baseStyles: Todo) => ({
      ...baseStyles,
      maxHeight: '100px',
    }),
  };

  return (
    <S.Wrapper>
      <ReactSelect
        options={reactSelectOptions}
        value={currentValue}
        onChange={(option) => {
          option && handleChangeTransfer(option.value);
        }}
        styles={styles}
        isDisabled={disabled}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => NO_OPTION_JAPANESE}
        placeholder="払出先の選択"
      />
    </S.Wrapper>
  );
};

export default MaterialAllocationTransferSelect;
