import { FC } from 'react';
import * as S from './TagEditMenuModal.styles';
// import type { Todo } from '@lib/common/type';

type Props = {
  open: boolean;
  relation: boolean;
  handleMasterEdit?: () => void;
  handleDelete?: () => void;
};

const TagEditMenuModal: FC<Props> = ({
  open,
  relation,
  handleMasterEdit,
  handleDelete,
}: Props) => {
  return (
    <S.Wrapper open={open}>
      <S.Edit onClick={handleMasterEdit}>編集する</S.Edit>
      {/* タグに紐づいているデータがない場合のみ削除は表示する。今後変更の可能性あり */}
      {!relation && <S.Delete onClick={handleDelete}>削除する</S.Delete>}
    </S.Wrapper>
  );
};

export default TagEditMenuModal;
