import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  background-color: ${(props) => props.theme.palette.beige};
  overflow-x: hidden;
  z-index: 4;
`;
