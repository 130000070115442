import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { NewEmployeesAndRoles } from './type';

const useEmployee = (id: string | number) => {
  const { data, refetch } = useQuery('selected-employee', async () => {
    const data = await axiosInstance().get(`/api/v1/settings/employees/${id}`);
    return data;
  });
  const employee = humps.camelizeKeys(
    data?.data?.employee
  ) as unknown as NewEmployeesAndRoles;
  return { employee, refetch };
};

export default useEmployee;
