import { useEffect, useState } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import * as S from './EmployeeCreateEditModal.styles';
import { WithHeader } from '@components/templates/WithHeader';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import EmployeeContents from '@components/modules/pc/settings/manufacture_setting/EmployeeContents/EmployeeContents';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import { Todo } from '@lib/common/type';
import { RolesInfo } from '@lib/pc/settings/manufacture_setting/type';
import useEmployee from '@lib/pc/settings/manufacture_setting/useEmployee';

type Props = {
  selectedEmployee: Todo;
  rolesInfo: RolesInfo[];
  refetch: () => void;
  setEmployeeModalActive: (employeeModalActive: boolean) => void;
  setSelectedEmployee: (selectedEmployee: Todo) => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
  setMsgKind: (msgKind: string) => void;
};

const EmployeeCreateEditModal = ({
  selectedEmployee,
  rolesInfo,
  refetch,
  setEmployeeModalActive,
  setSelectedEmployee,
  setUpdatePopUp,
  setMsgKind,
}: Props) => {
  const [newData, setNewData] = useState(selectedEmployee);

  // 確認メッセージ
  const [confirmMsg, setConfirmMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [enableToSave, setEnableToSave] = useState(false);
  const [updating, setUpdating] = useState(false);

  const employeeQuery = selectedEmployee?.id
    ? useEmployee(selectedEmployee.id)
    : null;

  // 使用時
  const employee = employeeQuery?.employee;

  // 確認メッセージOK
  const handleOk = () => {
    setConfirmMsg('');
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  useEffect(() => {
    refetch();
    setNewData(employee?.employee);
  }, [employee?.employee?.id, employee?.employee?.roleIds?.length]);

  return (
    <>
      <WithHeader>
        <S.Wrapper>
          <DndProvider backend={HTML5Backend}>
            {/* 従業員 */}
            <EmployeeContents
              employee={employee}
              newData={newData}
              setNewData={setNewData}
              rolesInfo={rolesInfo}
              enableToSave={enableToSave}
              setEnableToSave={setEnableToSave}
              updating={updating}
              setUpdating={setUpdating}
              refetch={refetch}
              setUpdatePopUp={setUpdatePopUp}
              setMsgKind={setMsgKind}
              setEmployeeModalActive={setEmployeeModalActive}
              setSelectedEmployee={setSelectedEmployee}
            />
          </DndProvider>
        </S.Wrapper>
      </WithHeader>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default EmployeeCreateEditModal;
