import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  position: relative;
  height: calc(100vh - 5rem);
`;

export const HeadContainer = styled.div`
  height: 88px;
  padding: 1.15rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FlexBaseline = styled.div`
  display: flex;
  align-items: baseline;
`;

export const ExpandButtonWrapper = styled.div`
  display: flex;
  border-radius: 5px;
  > span {
    margin-left: 8px;
  }
`;

export const Expand = styled.div`
  cursor: pointer;
  padding: 1rem 2rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & > a {
    width: 160px;
    height: 42px;
    padding: 10px 10px 10px 0;
    border-radius: 8px;
    color: ${(props) => props.theme.palette.white};
    background: ${(props) => props.theme.palette.primary.main};
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
  }
`;
