import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  justify-content: space-between;
`;

export const HeadContainer = styled.div`
  width: 100%;
  height: 70px;
  padding: 1.15rem 1.15rem 1.15rem 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CompanyName = styled.div`
  display: flex;
  > span {
    font-weight: 700;
    width: 150px;
  }
`;

export const ControlArea = styled.div`
  display: flex;
  gap: 24px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  left: 0;
  height: 83px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  right: 175px;
  width: 160px;
  height: 45px;
  padding: 10px 10px 10px 0;
  border-radius: 5px;
  color: ${(props) => props.theme.palette.white};
  background: ${(props) => props.theme.palette.primary.main};
  font-weight: 700;
  text-align: center;
  cursor: pointer;
`;

export const Table = styled.div<{
  paddingBottom?: string;
}>`
  width: 100vw;
  border-collapse: collapse;
  border-spacing: 0 1em;
  font-size: 0.875rem;
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : 0)};
  font-feature-settings: 'palt';
`;

export const Tr = styled.div`
  background-color: ${(props) => props.theme.palette.beige};
  display: flex;
  padding: 0 20px;
  height: 45px;
  width: 100vw;
  align-items: center;
  border-bottom: solid 1px #e5e1dd;
`;

// 絞り込み
export const ListLeftHeadCell = styled.div<{
  invalid?: boolean;
  isBlue?: boolean;
}>`
  pointer-events: ${(props) => (props.invalid ? 'none' : '')};
  cursor: pointer !important;
  position: -webkit-sticky; /* for Safari */
  display: flex;
  color: ${(props) => (props.isBlue ? props.theme.palette.blue : '#9ca3af')};
  align-items: center;

  > span {
    display: flex;
    font-size: 16px;
    vertical-align: middle;
    align-items: center;
    padding: 0 12px;
    height: 45px;
    > svg {
      display: inline;
      width: 24px;
      height: 24px;
    }
    .filter_text {
      padding-top: 5px;
      padding-left: 6px;
    }
  }

  :nth-child(2) {
    > div > input {
      cursor: pointer;
    }
  }
`;

export const TableContents = styled.div`
  overflow: scroll;
  width: 100vw;
  /* max-height: 100vh; */
  height: calc(100vh - 280px);
  padding-bottom: 130px;
  display: flex;
  position: relative;
`;

export const EmployeeColumn = styled.div`
  width: 150px;
  position: sticky;
  z-index: 3;
  left: 0px;
  border-right: 1px solid #e5e1dd;
  cursor: pointer;
  height: max-content;
  & > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }

  & > div:nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
  & > div:first-child {
    background-color: #f8f7f4;

    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    padding: 2px;
  }
`;

export const StyledTable = styled.div`
  width: 100%;
  font-feature-settings: 'palt';
  overflow-y: scroll;
`;

export const TRow = styled.div`
  cursor: pointer;
  border-bottom: 1px solid #e5e1dd;
  display: flex;
  min-width: 100vw;
  z-index: 2;
  padding: 0 20px;
  height: 45px;
  align-items: center;
`;
export const ListSidebar = styled.div`
  max-width: 250px;
  border-bottom: solid 1px #e5e1dd;
  height: max-content;
  width: 150px;
  height: 45px;
  position: sticky;
  left: -1px;
  z-index: 5;

  align-items: center;
  display: grid;

  > span {
    padding-left: 20px;
  }

  & > div:first-child {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    padding: 2px;
  }
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;
  height: max-content;
  background-color: aquamarine;

  & > div:nth-child(odd) {
    background: ${(props) => props.theme.palette.paleYellow};
  }

  & > div:nth-child(even) {
    background: ${(props) => props.theme.palette.white};
  }
  & > div:first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0px;
    z-index: 4;
    //COMMENT: 色確認、figmaと違う？ figma:#F5F2E6
    background-color: #f8f7f4;
  }
`;

export const Employee = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  position: fixed;
  position: -webkit-sticky; /* for Safari */
  left: -1px;
  z-index: 5;
  height: 45px;
`;

export const TCell = styled.div`
  font-size: 16px;
  font-weight: 500;
  &.code {
    min-width: 150px;
  }
  &.email {
    min-width: 300px;
  }
  &.role-name {
    min-width: 150px;
    display: flex;
    justify-content: center;
  }
  &.employee-name {
    min-width: 150px;
  }
  &.role-check {
    min-width: 150px;
    display: flex;
    justify-content: center;
  }
`;

// 従業員名タイトル
export const HeaderName = styled.div<{ isBlue: boolean }>`
  color: ${(props) => (props.isBlue ? props.theme.palette.blue : '')};
  border-bottom: solid 1px #e5e1dd;
  align-items: center;
  width: 150px;
  display: flex;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: 45px;
  position: relative;
  z-index: 6;
  background-color: #f8f7f4;
  padding: 0 20px;
  position: sticky;
  top: -1px;
  > span {
    padding-left: 20px;
  }
`;
