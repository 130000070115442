import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@lib/common/type';
import { barcodeParams } from '@lib/pc/product_production_result/type';
const useProductProductionResultsApiIncrement = (
  refetch: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: barcodeParams) => {
      return await axiosInstance().post(
        '/api/v1/product_production_results/increment',
        humps.decamelizeKeys(params)
      );
    },
    {
      onSuccess: () => {
        refetch();
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        setErrMsg(
          error.response?.data?.message ||
            'エラーが発生しました。再試行してください。'
        );
      },
    }
  );
};

export default useProductProductionResultsApiIncrement;
