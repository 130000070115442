export type SalesInformationState = {
  stateExpand: boolean;
  stateScrollPosition: number;
  stateSelectedEntryDate: Date | null;
  stateSearchEmployee: string;
  stateSearchCustomer: string;
  stateMsgKind: string;
};

export type DetailsAttributes = {
  id: number;
  productId: number;
  deliveryDate: string;
  salesStoreId: number;
  shippingDate: string;
  primaryEstimatedAmount: number;
  secondaryEstimatedAmount: number;
  fixedAmount: number;
  destroy: boolean;
};

export type SalesStoreInputParams = {
  customerId: number;
  employeeId: number;
  entryDate: string;
  title: string;
  comment: string;
  details: DetailsAttributes[];
};

const Customer = {
  id: '',
  name: '',
};

const Product = {
  id: null,
  name: '',
};

const SalesStore = {
  id: null,
  name: '',
};

export const Detail = {
  deliveryDate: '',
  fixedAmount: null,
  id: null,
  primaryEstimatedAmount: null,
  product: Product,
  salesStore: SalesStore,
  secondaryEstimatedAmount: null,
  shippingDate: '',
};

const Employee = {
  id: null,
  name: '',
};

export const initSalesInformation = {
  comment: '',
  customer: Customer,
  details: [Detail],
  employee: Employee,
  entryDate: '',
  id: null,
  title: '',
  errors: '',
};

export type InputParams = {
  customerId: number | null;
  employeeId: number | null;
  entryDate: string | null;
  title: string;
  comment: string;
  details: DetailParams[];
};

export type DetailParams = {
  productId: number | null;
  deliveryDate: string | null;
  shippingDate: string | null;
  salesStoreId: number | null;
  primaryEstimatedAmount: number | null;
  secondaryEstimatedAmount: number | null;
  fixedAmount: number | null;
};
