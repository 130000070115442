import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';
import { InputParams } from '@lib/pc/sales_information/type';

const useCreateSalesInformationMutation = (
  handleCreateSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: InputParams) => {
      const apiParams = {
        ...params,
        detailsAttributes: params.details,
      };
      return await axiosInstance().post(
        '/api/v1/sales_informations',
        humps.decamelizeKeys(apiParams)
      );
    },
    {
      onSuccess: () => {
        handleCreateSuccess();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message);
      },
    }
  );
};

export default useCreateSalesInformationMutation;
