import { PCPath } from '@config/path';
import { ReactComponent as CardBoardBox } from '@assets/icons/cardboard.svg';
import { ReactComponent as FlourIcon } from '@assets/icons/flour.svg';
import { ReactComponent as GraphIcon } from '@assets/icons/graph.svg';
// import { ReactComponent as PencilIcon } from '@assets/icons/pencil_gray.svg';
import { ReactComponent as ProductionLineIcon } from '@assets/icons/production_line.svg';
import { ReactComponent as SettingsIcon } from '@assets/icons/settings.svg';
import { ReactComponent as TrackIcon } from '@assets/icons/track.svg';

export type SidebarLinkType = {
  icon?: React.FunctionComponent | React.ComponentType;
  title: string;
  function: string;
  links: { label: string; url: PCPath | ''; function: string }[];
};

export const sidebarLinkData: SidebarLinkType[] = [
  {
    icon: GraphIcon,
    title: '生産計画',
    function: 'productionSchedule',
    links: [
      // {
      //   label: '【完成品】生産計画',
      //   url: PCPath.productionSchedule,
      //   function: 'productProductionSchedule',
      // },
      {
        label: '【完成品】生産計画',
        url: PCPath.basisForProductionSchedule,
        function: 'productProductionSchedule',
      },
      {
        label: '【半製品】生産計画',
        url: PCPath.semiProductionSchedule,
        function: 'semiProductProductionSchedule',
      },
    ],
  },
  {
    title: '包装',
    function: 'wrappingStatus',
    links: [
      {
        label: '包装実績（入力）',
        url: PCPath.wrappingStatus,
        function: 'wrappingStatus',
      },
    ],
  },
  {
    title: 'スタッカー',
    function: 'stacker',
    links: [
      {
        label: 'スタッカー実績（入力）',
        url: PCPath.stacker,
        function: 'stacker',
      },
    ],
  },
  {
    icon: ProductionLineIcon,
    title: '生産実績',
    function: 'productProductionResult',
    links: [
      {
        label: '【完成品】生産実績（入力）',
        url: PCPath.productProductionResult,
        function: 'inputProductProductionResult',
      },
      {
        label: '【半製品】生産実績（入力）',
        url: PCPath.semiProductProductionResult,
        function: 'inputSemiProductProductionResult',
      },
      {
        label: '【完成品】生産実績',
        url: PCPath.productProductionResultPc,
        function: 'productProductionResult',
      },
      {
        label: '【半製品】生産実績',
        url: PCPath.semiProductProductionResultPc,
        function: 'semiProductProductionResult',
      },
    ],
  },
  {
    icon: CardBoardBox, // Iconを追加
    title: '製品在庫',
    function: 'stockStatus',
    links: [
      { label: '在庫状況', url: PCPath.stockStatus, function: 'stockStatus' },
      {
        label: '【完成品】払出状況（入力）',
        url: PCPath.stockAllocationStatus,
        function: 'stockAllocationStatus',
      },
      {
        label: '【半製品】払出状況（入力）',
        url: PCPath.semiProductStockAllocationAll,
        function: 'semiProductStockAllocationStatus',
      },
      {
        label: '払出状況',
        url: PCPath.stockAllocationAll,
        function: 'stockAllocationAll',
      },
    ],
  },
  // {
  //   title: '受注',
  //   function: 'orderSummary',
  //   links: [
  //     { label: '受注状況', url: PCPath.orderSummary, function: 'orderSummary' },
  //   ],
  // },
  {
    icon: TrackIcon,
    title: '受注・出荷',
    function: 'picking',
    links: [
      { label: '受注状況', url: PCPath.orderSummary, function: 'orderSummary' },
      {
        label: 'ピッキング（商品別）',
        url: PCPath.picking,
        function: 'pickingByProduct',
      },
      {
        label: 'ピッキング（出荷先別）',
        url: PCPath.pickingByShipping,
        function: 'pickingByShipping',
      },
      {
        label: '出荷実績',
        url: PCPath.shippingStatus,
        function: 'pickingByShipping',
      },
      {
        label: '受注',
        url: PCPath.acceptingOrders,
        function: 'acceptingOrders',
      },
    ],
  },
  {
    icon: FlourIcon,
    title: '資材・原材料在庫',
    function: 'materialInventory',
    links: [
      {
        label: '資材・原材料在庫状況',
        url: PCPath.materialInventory,
        function: 'materialInventory',
      },
      {
        label: '資材払出状況（入力）',
        url: PCPath.materialAllocation,
        function: 'materialAllocation',
      },
      {
        label: '原材料払出状況（入力）',
        url: PCPath.rowMaterialAllocation,
        function: 'rowMaterialAllocation',
      },
      {
        label: '資材・原材料払出状況',
        url: PCPath.materialAllocationStatus,
        function: 'materialAllocationStatus',
      },
      {
        label: '発注',
        url: PCPath.purchaseOrders,
        function: 'purchaseOrders',
      },
    ],
  },
  {
    title: '営業',
    function: 'salesInformation',
    links: [
      {
        label: '営業情報入力',
        url: PCPath.salesInformation,
        function: 'salesInformation',
      },
    ],
  },
  {
    title: '会社情報管理',
    function: 'manufactureInformation',
    links: [
      {
        label: '会社情報管理',
        url: PCPath.settingsManufacture,
        function: 'manufactureInformation',
      },
    ],
  },
  {
    icon: SettingsIcon,
    title: '製品管理',
    function: 'settings',
    links: [
      {
        label: '【完成品】製品マスタ管理',
        url: PCPath.settingsProductMaster,
        function: 'productMaster',
      },
      {
        label: '【半製品】製品マスタ管理',
        url: PCPath.settingsSemiProductMaster,
        function: 'semiProductMaster',
      },
    ],
  },
  {
    icon: SettingsIcon,
    title: '資材・原材料管理',
    function: 'materialSettings',
    links: [
      {
        label: '資材・原材料マスタ管理',
        url: PCPath.settingsMaterialMaster,
        function: 'materialMaster',
      },
      {
        label: '仕入先管理',
        url: PCPath.settingsSupplierMaster,
        function: 'supplierMaster',
      },
    ],
  },
  {
    icon: SettingsIcon,
    title: '受注・出荷管理',
    function: 'orderShipmentSettings',
    links: [
      {
        label: '得意先管理',
        url: PCPath.settingsCustomerMaster,
        function: 'customerMaster',
      },
      {
        label: '出荷先グループ管理',
        url: PCPath.settingsShippingGroupMaster,
        function: 'shippingGroupMaster',
      },
      {
        label: '出荷先管理',
        url: PCPath.settingsShippingDestinationMaster,
        function: 'shippingDestinationMaster',
      },
      {
        label: '販売店管理',
        url: PCPath.salesStoreMaster,
        function: 'salesStoreMaster',
      },
    ],
  },

  // {
  //   title: '受注集計表',
  //   links: [
  //     { label: '受注集計表：単日', url: '' },
  //     { label: '受注集計表：複数日', url: '' },
  //   ],
  // },
  // {
  //   title: 'ピッキング',
  //   links: [{ label: 'ピッキングの進捗を確認', url: PCPath.picking }],
  // },
];
