import styled from 'styled-components';

const padding = {
  sm: '0.5rem 0',
  md: '0.75rem 0',
  lg: '1rem 1.6rem',
} as const;

export const Wrapper = styled('button')<{
  outlined?: boolean;
  padding?: 'sm' | 'md' | 'lg';
  borderWidth?: number;
  width?: string;
  height?: string;
  margin?: string;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: ${(props) =>
    props.outlined
      ? props.theme.palette.white
      : props.disabled
      ? '#e0e0e0'
      : props.theme.palette.primary.main};
  color: ${(props) =>
    props.disabled
      ? props.outlined
        ? '#e0e0e0'
        : '#6b675c'
      : props.outlined
      ? props.theme.palette.primary.main
      : props.theme.palette.primary.contrastText};
  border: ${(props) =>
    props.disabled
      ? `2px solid #e0e0e0`
      : `2px solid ${props.theme.palette.primary.main}`};
  font-weight: bold;
  font-size: 16px;
  text-align: center;

  border-radius: 8px;
  padding: ${(props) =>
    props.padding ? padding[props.padding] : padding['md']};
  border-width: ${(props) =>
    props.borderWidth ? props.borderWidth + 'px' : '2px'};
  width: ${(props) => props.width || 'auto'};
  margin: ${(props) => (props.margin ? props.margin : '')};
  height: ${(props) => (props.height ? props.height : '')};
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
`;
