import humps from 'humps';
import moment from 'moment';
import { useQuery } from 'react-query';
import { SalesInformation } from '@lib/common/type';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';

export const useSalesInformationList = (
  date: string | Date | null,
  searchEmployee: string,
  searchCustomer: string
) => {
  const { data, refetch } = useQuery(
    ['salesInformationList', date, searchEmployee, searchCustomer],
    async () => {
      const data = await axiosInstance().get(
        `/api/v1/sales_informations?date=${
          date ? moment(date).format('YYYYMMDD') : ''
        }&employee=${searchEmployee}&customer=${searchCustomer}`
      );

      return data;
    }
  );

  const salesInformationList =
    data &&
    (humps.camelizeKeys(data.data.sales_informations) as SalesInformation[]);

  return { salesInformationList, refetch };
};

export default useSalesInformationList;
