import { useEffect, useState } from 'react';
import * as S from './EmployeeContents.styles';
import Checkbox from '@material-ui/core/Checkbox';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { cyan } from '@material-ui/core/colors';
import { Button } from '@components/elements/buttons/Button';
import { Heading } from '@components/elements/typographies/Heading';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import {
  Employee,
  initEmployeeData,
  LineAssignments,
  RolesInfo,
} from '@lib/pc/settings/manufacture_setting/type';
import { Todo } from '@lib/common/type';
import useUpdateEmployeeMutation from '@lib/pc/settings/manufacture_setting/useUpdateEmployee';
import useCreateEmployee from '@lib/pc/settings/manufacture_setting/useCreateEmployee';

type Props = {
  employee: Todo;
  newData: Employee;
  setNewData: (newData: Todo) => void;
  rolesInfo: RolesInfo[];
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  enableToSave: boolean;
  setEnableToSave: (enableToSave: boolean) => void;
  refetch?: () => void;
  setUpdatePopUp: (updatePopUp: boolean) => void;
  setMsgKind: (msgKind: string) => void;
  setEmployeeModalActive: (employeeModalActive: boolean) => void;
  setSelectedEmployee: (selectedEmployee: Todo) => void;
};

const EmployeeContents = ({
  employee,
  newData,
  setNewData,
  rolesInfo,
  updating,
  setUpdating,
  enableToSave,
  setEnableToSave,
  refetch,
  setUpdatePopUp,
  setMsgKind,
  setEmployeeModalActive,
  setSelectedEmployee,
}: Props) => {
  const [errMsg, setErrMsg] = useState('');
  const [confirmMsg, setConfirmMsg] = useState('');

  // 登録か保存か判定
  const createMode = employee ? false : true;

  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...newData, [e.target.name]: e.target.value };
    setNewData(data);
    setEnableToSave(true);
  };

  const handleUpdateSuccess = () => {
    refetch && refetch();
    setUpdatePopUp(true);
    setUpdating(false);
    setEnableToSave(false);
    setEmployeeModalActive(false);
    setNewData(initEmployeeData);
    reRender();
    if (createMode) {
      setMsgKind('create');
    } else {
      setMsgKind('update');
    }
  };

  const handleCheckbox = (roleId: number) => {
    if (newData?.roleIds.includes(roleId)) {
      // 含まれていたら外す
      const newRoleIds = newData?.roleIds.filter((num) => num !== roleId);
      setNewData({ ...newData, roleIds: newRoleIds });
    } else {
      newData?.roleIds.push(roleId);
    }
    reRender();
    setEnableToSave(true);
  };

  const updateEmployeeMutation = useUpdateEmployeeMutation(
    newData?.id,
    handleUpdateSuccess,
    setErrMsg
  );

  // ***********************
  // [保存ボタン]押下、更新処理
  // ***********************
  const handleSubmitUpdate = () => {
    setUpdating(true);
    updateEmployeeMutation.mutate(newData);
  };

  const createEmployee = useCreateEmployee(handleUpdateSuccess, setErrMsg);

  // ***********************
  // [登録ボタン]押下、作成処理
  // ***********************
  const handleSubmitCreate = () => {
    setUpdating(true);
    createEmployee.mutate(newData);
  };

  const theme = createTheme({
    palette: {
      primary: cyan,
    },
  });

  const handleClickCancel = () => {
    if (enableToSave) {
      setConfirmMsg('変更した内容を保存せずに終了します。よろしいですか？');
    } else {
      setEmployeeModalActive(false);
      setSelectedEmployee(initEmployeeData);
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    setEmployeeModalActive(false);
    setEnableToSave(false);
    setSelectedEmployee(initEmployeeData);
  };

  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  useEffect(() => {
    if (employee) {
      setNewData(employee?.employee);
    } else {
      setNewData(initEmployeeData);
    }
  }, [employee?.roleIds?.length]);

  return (
    <>
      <S.HeadContainer>
        {createMode ? (
          <Heading tag="span" text="従業員の登録" fs="32" fw="lg" />
        ) : (
          <Heading tag="span" text="従業員の編集" fs="32" fw="lg" />
        )}
      </S.HeadContainer>
      <S.Wrapper>
        <S.ShippingGroupAreaDl>
          <dt>従業員コード</dt>
          <dd>
            <S.Input
              type="text"
              inputMode="numeric"
              name="code"
              id="code"
              autoComplete="off"
              width={'25%'}
              value={newData?.code ? newData?.code : ''}
              onChange={handleChange}
            />
          </dd>
          <dt>従業員名</dt>
          <dd>
            <S.Input
              type="text"
              inputMode="numeric"
              name="name"
              id="name"
              autoComplete="off"
              width={'25%'}
              value={newData?.name ? newData?.name : ''}
              onChange={handleChange}
            />
          </dd>
          <dt>メールアドレス</dt>
          <dd>
            <S.Input
              type="text"
              inputMode="numeric"
              name="email"
              id="email"
              autoComplete="off"
              width={'50%'}
              value={newData?.email ? newData?.email : ''}
              onChange={handleChange}
            />
          </dd>
          {!createMode && (
            <>
              <dt>担当業務</dt>
              <div className="roles-row">
                <S.RolesNameWrapper>
                  {rolesInfo &&
                    rolesInfo.map((role: RolesInfo, i) => {
                      return (
                        <S.EachRole key={i + role?.role}>
                          <S.RolesTitle key={i + role?.role + newData?.name}>
                            {role?.role}
                          </S.RolesTitle>
                          <S.RolesCheck key={i + role?.role}>
                            <ThemeProvider theme={theme}>
                              <Checkbox
                                id="checkboxId"
                                color="primary"
                                checked={
                                  newData?.roleIds
                                    ? newData?.roleIds?.includes(role?.roleId)
                                    : false
                                }
                                onChange={() => handleCheckbox(role.roleId)}
                              />
                            </ThemeProvider>
                          </S.RolesCheck>
                        </S.EachRole>
                      );
                    })}
                </S.RolesNameWrapper>
              </div>
            </>
          )}
          {!createMode && newData?.lineAssignments?.length > 0 && (
            <>
              <dt className="line-assignments">担当ライン</dt>
              {newData?.lineAssignments.map(
                (lineAssignment: LineAssignments, i: number) => {
                  return (
                    <S.LineAssignments
                      key={
                        i + lineAssignment.factoryName + lineAssignment.lineName
                      }
                    >
                      <S.EachLineAssignments>
                        <S.FactoryName>
                          {lineAssignment.factoryName}
                        </S.FactoryName>
                        {lineAssignment.lineName}
                      </S.EachLineAssignments>
                    </S.LineAssignments>
                  );
                }
              )}
            </>
          )}
        </S.ShippingGroupAreaDl>
      </S.Wrapper>
      <S.ButtonWrapper>
        <S.ButtonContainer>
          <Button
            children={'キャンセル'}
            borderWidth={1}
            outlined
            onClick={() => {
              handleClickCancel();
            }}
            disabled={updating}
          />

          {createMode ? (
            <Button
              children={updating ? '登録中...' : '登録'}
              disabled={updating || !enableToSave}
              onClick={handleSubmitCreate}
            />
          ) : (
            <Button
              children={updating ? '保存中...' : '保存'}
              disabled={updating || !enableToSave}
              onClick={handleSubmitUpdate}
            />
          )}
        </S.ButtonContainer>
      </S.ButtonWrapper>
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => {
          setErrMsg('');
          setUpdating(false);
        }}
        fromPc={true}
        zIndex={9999}
      />
      {errMsg && (
        <Overlay
          dark
          handleClick={() => {
            setErrMsg('');
            setUpdating(false);
          }}
        />
      )}
      <ConfirmPopUp
        fromPc={true}
        confirmMsg={confirmMsg}
        zIndex={1004}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default EmployeeContents;
