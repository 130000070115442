import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { Todo } from '@lib/common/type';

const useSalesInformation = (id: string | undefined) => {
  const { data, refetch } = useQuery(['salesInformation', id], async () => {
    const data = await axiosInstance().get(`/api/v1/sales_informations/${id}`);

    return data;
  });

  const salesInformation = data && (humps.camelizeKeys(data.data) as Todo);

  return { salesInformation, refetch };
};

export default useSalesInformation;
