import React, { useState } from 'react';
import * as S from './EmployeeInfo.styles';
import Filter from '@assets/icons/filter_list';
import { ReactComponent as CheckIcon } from '@assets/icons/check_square_24px.svg';
import { ReactComponent as FilteredIcon } from '@assets/icons/filtered.svg';
import EmployeeCreateEditModal from '@components/modules/pc/settings/manufacture_setting/EmployeeCreateEditModal/EmployeeCreateEditModal';
import Overlay from '@components/elements/backdrops/Overlay/Overlay';
import SearchMenu from '@components/modules/pc/picking/SearchMenu/SearchMenu';
import UpdatePopUp from '@components/modules/pc/settings/common/UpdatePopUp/UpdatePopUp';
import {
  EmployeesAndRoles,
  EmployeesInfo,
  initEmployeeData,
} from '@lib/pc/settings/manufacture_setting/type';
import { Todo } from '@lib/common/type';

type Props = {
  employeesInfo: EmployeesInfo;
  refetch: () => void;
};

const EmployeeInfo = ({ employeesInfo, refetch }: Props) => {
  // 検索modal表示
  const [searchModalActive, setSearchModalActive] = useState(false);
  // 検索文字列
  const [searchWord, setSearchWord] = useState('');
  const [employeeModalActive, setEmployeeModalActive] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(initEmployeeData);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [msgKind, setMsgKind] = useState('');

  // 検索配送先グループ名
  const handleSetWordSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value);
  };

  const FilterFunctionIcon = () => {
    return (
      <S.ListLeftHeadCell
        onClick={() => setSearchModalActive(!searchModalActive)}
        isBlue={searchWord !== ''}
      >
        <span>
          {searchWord !== '' ? (
            <FilteredIcon />
          ) : (
            <Filter isBlue={searchWord !== ''} />
          )}
        </span>
      </S.ListLeftHeadCell>
    );
  };

  const handleClickCell = (e: Todo) => {
    return new Promise<void>((resolve) => {
      setSelectedEmployee(e);
      resolve();
    }).then(() => {
      setEmployeeModalActive(true);
    });
  };

  return (
    <S.Wrapper>
      <S.HeadContainer className="head-container">
        <S.CompanyName>
          <span>会社名</span>
          {employeesInfo?.manufactureName}
        </S.CompanyName>

        <S.ControlArea className="control-area">
          {/* 新規登録ボタン */}
          <S.ButtonWrapper>
            <S.ButtonContainer
              onClick={() => {
                setUpdatePopUp(false);
                setEmployeeModalActive(true);
                setSelectedEmployee(initEmployeeData);
              }}
            >
              ＋ 新規登録
            </S.ButtonContainer>
          </S.ButtonWrapper>
        </S.ControlArea>
      </S.HeadContainer>
      <S.TableContents className="table-contents" id="table-contents">
        <S.EmployeeColumn className="employee-column">
          <S.HeaderName
            isBlue={searchWord !== ''}
            onClick={() => {
              setSearchModalActive(!searchModalActive);
            }}
          >
            <span>従業員名</span>
            <FilterFunctionIcon />
          </S.HeaderName>
          {employeesInfo &&
            employeesInfo?.employeesAndRoles
              .filter(
                (er: EmployeesAndRoles) =>
                  er?.name?.includes(searchWord) ||
                  er?.code?.includes(searchWord)
              )
              .map((e, i) => {
                return (
                  <S.ListSidebar
                    className="list-side-bar"
                    key={e?.name + i}
                    onClick={() => {
                      if (e?.name) {
                        handleClickCell(e);
                        setUpdatePopUp(false);
                      }
                    }}
                  >
                    <span>{e?.name}</span>
                  </S.ListSidebar>
                );
              })}
        </S.EmployeeColumn>

        <S.ListBody>
          <S.TRow>
            <S.TCell className="code">従業員コード</S.TCell>
            <S.TCell className="email">メールアドレス</S.TCell>
            {employeesInfo &&
              employeesInfo?.rolesInfo.map((role, i) => {
                return (
                  <S.TCell className="role-name" key={i + role.role}>
                    {role.role}
                  </S.TCell>
                );
              })}
          </S.TRow>

          {employeesInfo &&
            employeesInfo?.employeesAndRoles
              .filter(
                (as: EmployeesAndRoles) =>
                  as?.name?.includes(searchWord) ||
                  as?.code?.includes(searchWord)
              )
              .map((e, i) => {
                return (
                  <React.Fragment key={i + e.name}>
                    <S.TRow
                      key={i}
                      onClick={() => {
                        if (e.name) {
                          handleClickCell(e);
                          setUpdatePopUp(false);
                        }
                      }}
                    >
                      <S.TCell className="code">{e.code}</S.TCell>
                      <S.TCell className="email">{e.email}</S.TCell>
                      {employeesInfo &&
                        employeesInfo?.rolesInfo.map((role, i) => {
                          return (
                            <S.TCell className="role-check" key={i}>
                              {e.roleIds.includes(role.roleId) ? (
                                <CheckIcon />
                              ) : (
                                ''
                              )}
                            </S.TCell>
                          );
                        })}
                    </S.TRow>
                  </React.Fragment>
                );
              })}
        </S.ListBody>
      </S.TableContents>
      {searchModalActive && (
        <SearchMenu
          title={'従業員名/コード'}
          searchParam={searchWord}
          handleClose={() => setSearchModalActive(false)}
          handleChange={handleSetWordSearch}
          left={'0.5rem'}
          top={'268px'}
          deleteSearchParam={setSearchWord}
        />
      )}
      {searchModalActive && (
        <Overlay
          handleClick={() => {
            setSearchModalActive(false);
          }}
        />
      )}
      {employeeModalActive && (
        <EmployeeCreateEditModal
          selectedEmployee={selectedEmployee}
          rolesInfo={employeesInfo?.rolesInfo}
          refetch={refetch}
          setEmployeeModalActive={setEmployeeModalActive}
          setSelectedEmployee={setSelectedEmployee}
          setUpdatePopUp={setUpdatePopUp}
          setMsgKind={setMsgKind}
        />
      )}
      <UpdatePopUp
        popUp={updatePopUp}
        taskKind={'messageOnly'}
        messageKind={msgKind}
        handleClose={() => setUpdatePopUp(false)}
      />
    </S.Wrapper>
  );
};

export default EmployeeInfo;
