import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import type { SalesStore } from '@lib/common/type';

const useSalesStores = (customerId: number) => {
  const { data, refetch } = useQuery(['sales_stores', customerId], async () => {
    const { data } = await axiosInstance().get(
      `/api/v1/sales_stores?customer_id=${customerId}`
    );
    return data;
  });

  const salesStores =
    data && (humps.camelizeKeys(data.sales_stores) as SalesStore[]);

  return { salesStores, refetch };
};

export default useSalesStores;
