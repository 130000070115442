import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { useQuery } from 'react-query';
import humps from 'humps';
import { ManufactureInfoType } from './type';

const useManufacture = () => {
  const { data, refetch } = useQuery('manufacture', async () => {
    const data = await axiosInstance().get(`/api/v1/manufactures`);
    return data;
  });
  const manufactureInfo = humps.camelizeKeys(
    data?.data?.manufactures
  ) as unknown as ManufactureInfoType;
  return { manufactureInfo, refetch };
};

export default useManufacture;
