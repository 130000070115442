import { useEffect } from 'react';
import PCLoginPage from '@components/pages/pc/LoginPage/PCLoginPage';
import { SalesInformationRoot } from '@components/pages/pc/SalesInformation';
import { SalesInformationNewRoot } from '@components/pages/pc/SalesInformationNew';
import { SalesInformationEditRoot } from '@components/pages/pc/SalesInformationEdit';
import { BasisForProductionScheduleRoot } from '@components/pages/pc/ProductionSchedule';
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import PCAuthRoute from './PCAuthRoute';
import { MaterialAllocationRoot } from '@components/pages/pc/MaterialAllocation/MaterialAllocation';
import { RowMaterialAllocationRoot } from '@components/pages/pc/RowMaterialAllocation/RowMaterialAllocation';
import { MaterialAllocationStatusRoot } from '@components/pages/pc/MaterialAllocationStatus/MaterialAllocationStatus';
import { MaterialInventoryRoot } from '@components/pages/pc/MaterialInventory/MaterialInventory';
import { ShippingStatusRoot } from '@components/pages/pc/ShippingStatus/ShippingStatus';
import { StockAllocationAllRoot } from '@components/pages/pc/StockAllocationAll';
import { StockAllocationStatusRoot } from '@components/pages/pc/StockAllocationStatus';
import { SemiProductStockAllocationStatusRoot } from '@components/pages/pc/SemiProductStockAllocationStatus';
import { StockStatusRoot } from '@components/pages/pc/StockStatus';
import { OrderSummaryRoot } from '@components/pages/pc/OrderSummary';
// import PickingRoute from '@pc/routes/routes/PickingRoute';
import { StackerRoot } from '@components/pages/pc/Stacker';
import { WrappingStatusRoot } from '@components/pages/pc/WrappingStatus';
import { ProductProductionResultRoot } from '@components/pages/pc/ProductProductionResult';
import { SemiProductProductionResultRoot } from '@components/pages/pc/SemiProductProductionResult';
import { ProductMasterSettingRoot } from '@components/pages/pc/Settings/ProductMasterSetting/ProductMasterRoot/ProductMasterSettingRoot';
import { ProductMasterCreateRoot } from '@components/pages/pc/Settings/ProductMasterSetting/ProductMasterCreate/ProductMasterCreateRoot';
import { ProductMasterEditRoot } from '@components/pages/pc/Settings/ProductMasterSetting/ProductMasterEditRoot/ProductMasterEditRoot';
import { SemiProductMasterSettingRoot } from '@components/pages/pc/Settings/SemiProductMasterSetting/SemiProductMaster/SemiProductMaster';
import { SemiProductMasterCreateRoot } from '@components/pages/pc/Settings/SemiProductMasterSetting/SemiProductMasterCreate/SemiProductMasterCreate';
import { SemiProductMasterEditRoot } from '@components/pages/pc/Settings/SemiProductMasterSetting/SemiProductMasterEdit/SemiProductMasterEdit';
import { MaterialMasterSettingRoot } from '@components/pages/pc/Settings/MaterialMasterSetting/MaterialMaster/MaterialMasterSettingRoot';
import { MaterialMasterEditRoot } from '@components/pages/pc/Settings/MaterialMasterSetting/MaterialMasterEdit/MaterialMasterEditRoot';
import { MaterialMasterCreateRoot } from '@components/pages/pc/Settings/MaterialMasterSetting/MaterialMasterCreate/MaterialMasterCreateRoot';
import { SemiProductionScheduleRoot } from '@components/pages/pc/SemiProductionSchedule';
import { ProductProductionResultPcRoot } from '@components/pages/pc/ProductProductionResultPc';
import { SemiProductProductionResultPcRoot } from '@components/pages/pc/SemiProductProductionResultPc';
// import { OrderSummaryRoot } from '@components/pages/pc/OrderSummary/OrderSummary';
import { PickingRoot } from '@components/pages/pc/picking/PickingRoot';
import { PickingDetailRoot } from '@components/pages/pc/picking/PickingDetail';
import { PickingByShippingRoot } from '@components/pages/pc/PickingByShipping/PickingByShippingRoot';
import { PickingByShippingDetailRoot } from '@components/pages/pc/PickingByShipping/PickingByShippingDetail';
import { PurchaseOrdersRoot } from '@components/pages/pc/PurchaseOrders';
import { PurchaseOrdersNewRoot } from '@components/pages/pc/PurchaseOrdersNew';
import { PurchaseOrdersEditRoot } from '@components/pages/pc/PurchaseOrdersEdit';
import { AcceptingOrdersRoot } from '@components/pages/pc/AcceptingOrders';
import { AcceptingOrdersEditRoot } from '@components/pages/pc/AcceptingOrdersEdit';
import { ShippingGroupMasterRoot } from '@components/pages/pc/Settings/ShippingGroupSetting/ShippingGroupMaster/ShippingGroupMasterRoot';
import { ShippingGroupMasterEditRoot } from '@components/pages/pc/Settings/ShippingGroupSetting/ShippingGroupMasterEdit';
import { ShippingGroupMasterCreateRoot } from '@components/pages/pc/Settings/ShippingGroupSetting/ShippingGroupMasterCreate';
import { ShippingDestinationMasterRoot } from '@components/pages/pc/Settings/ShippingDestinationMasterSetting/ShippingDestinationMaster/ShippingDestinationMasterRoot';
import { ShippingDestinationMasterCreateRoot } from '@components/pages/pc/Settings/ShippingDestinationMasterSetting/ShippingDestinationMasterCreate/ShippingDestinationMasterCreate';
import { ShippingDestinationMasterEditRoot } from '@components/pages/pc/Settings/ShippingDestinationMasterSetting/ShippingDestinationMasterEdit/ShippingDestinationMasterEdit';
import { CustomerMasterRoot } from '@components/pages/pc/Settings/CustomerSetting/CustomerMaster/CustomerMasterRoot';
import { CustomerMasterEditRoot } from '@components/pages/pc/Settings/CustomerSetting/CustomerMasterEdit/CustomerMasterEdit';
import { CustomerMasterCreateRoot } from '@components/pages/pc/Settings/CustomerSetting/CustomerMasterCreate/CustomerMasterCreate';
import { SupplierMasterRoot } from '@components/pages/pc/Settings/SuppliersSetting/SupplierMaster/SupplierMasterRoot';
import { SupplierMasterEditRoot } from '@components/pages/pc/Settings/SuppliersSetting/SupplierMasterEdit/SupplierMasterEdit';
import { SupplierMasterCreateRoot } from '@components/pages/pc/Settings/SuppliersSetting/SupplierMasterCreate/SupplierMasterCreate';
import { SalesStoreMasterRoot } from '@components/pages/pc/Settings/SalesStoreSetting/SalesStoreMaster/SalesStoreMasterRoot';
import { SalesStoreMasterEditRoot } from '@components/pages/pc/Settings/SalesStoreSetting/SalesStoreMasterEdit/SalesStoreMasterEdit';
import { SalesStoreMasterCreateRoot } from '@components/pages/pc/Settings/SalesStoreSetting/SalesStoreMasterCreate/SalesStoreMasterCreate';
import { ManufactureEditRoot } from '@components/pages/pc/Settings/ManufactureSetting/ManufactureEditRoot/ManufactureEditRoot';

const PCRoutes = () => {
  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    // NOTE: service workerをページを移動するたびにupdateチェックする
    history.listen(() => {
      if (navigator.serviceWorker) {
        navigator.serviceWorker
          .getRegistrations()
          .then((registrations) =>
            registrations.forEach((reg) => reg.update())
          );
      }
    });
  }, [history]);

  return (
    <Switch>
      <Route path={`${match.url}`} exact>
        <Redirect to={`${match.url}/login`} />
      </Route>
      <Route path={`${match.url}/login`} component={PCLoginPage} />
      <PCAuthRoute
        path={`${match.url}/material_allocation`}
        component={MaterialAllocationRoot}
        menuFunction={'materialInventory'}
      />
      <PCAuthRoute
        path={`${match.url}/row_material_allocation`}
        component={RowMaterialAllocationRoot}
        menuFunction={'materialInventory'}
      />
      <PCAuthRoute
        path={`${match.url}/material_inventory`}
        component={MaterialInventoryRoot}
        menuFunction={'materialInventory'}
      />
      <PCAuthRoute
        path={`${match.url}/material_allocation_status`}
        component={MaterialAllocationStatusRoot}
        menuFunction={'materialInventory'}
      />
      <PCAuthRoute
        path={`${match.url}/purchase_orders`}
        component={PurchaseOrdersRoot}
        menuFunction={'materialInventory'}
        exact
      />
      <PCAuthRoute
        path={`${match.url}/purchase_orders/new`}
        component={PurchaseOrdersNewRoot}
        menuFunction={'materialInventory'}
        exact
      />
      <PCAuthRoute
        path={`${match.url}/purchase_orders/:id/edit`}
        component={PurchaseOrdersEditRoot}
        menuFunction={'materialInventory'}
        exact
      />
      <PCAuthRoute
        path={`${match.url}/production_schedule`}
        component={BasisForProductionScheduleRoot}
        // NOTE: menuFunctionはバックエンドのUserAndRoleの名称
        menuFunction={'productionSchedule'}
      />
      <PCAuthRoute
        path={`${match.url}/semi_production_schedule`}
        component={SemiProductionScheduleRoot}
        menuFunction={'productionSchedule'}
      />
      <PCAuthRoute
        path={`${match.url}/stacker`}
        component={StackerRoot}
        menuFunction={'stacker'}
      />
      {/* <PCAuthRoute
        path={`${match.url}/picking`}
        component={PickingRoute}
        menuFunction={'picking'}
      /> */}
      <PCAuthRoute
        path={`${match.url}/sales_information/new`}
        component={SalesInformationNewRoot}
        menuFunction={'salesInformation'}
        exact
      />
      <PCAuthRoute
        path={`${match.url}/sales_information/edit/:id`}
        component={SalesInformationEditRoot}
        menuFunction={'salesInformation'}
        exact
      />
      <PCAuthRoute
        path={`${match.url}/sales_information`}
        component={SalesInformationRoot}
        menuFunction={'salesInformation'}
        exact
      />
      <PCAuthRoute
        path={`${match.url}/stock_status`}
        component={StockStatusRoot}
        menuFunction={'stockStatus'}
      />
      <PCAuthRoute
        path={`${match.url}/stock_allocation_all`}
        component={StockAllocationAllRoot}
        menuFunction={'stockStatus'}
      />
      <PCAuthRoute
        path={`${match.url}/stock_allocation_status`}
        component={StockAllocationStatusRoot}
        menuFunction={'stockStatus'}
      />
      <PCAuthRoute
        path={`${match.url}/semi_product_stock_allocation_status`}
        component={SemiProductStockAllocationStatusRoot}
        menuFunction={'stockStatus'}
      />
      <PCAuthRoute
        path={`${match.url}/order_summary`}
        component={OrderSummaryRoot}
        menuFunction={'orderSummary'}
      />
      <PCAuthRoute
        path={`${match.url}/wrapping_status`}
        component={WrappingStatusRoot}
        menuFunction={'wrappingStatus'}
      />
      <PCAuthRoute
        path={`${match.url}/product_production_result`}
        component={ProductProductionResultRoot}
        menuFunction={'productProductionResult'}
      />
      <PCAuthRoute
        path={`${match.url}/semi_product_production_result`}
        component={SemiProductProductionResultRoot}
        menuFunction={'productProductionResult'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/product_master_setting`}
        component={ProductMasterSettingRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/product_master_setting_create`}
        component={ProductMasterCreateRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/product_master_setting_edit/:id`}
        component={ProductMasterEditRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/semi_product_master_setting`}
        component={SemiProductMasterSettingRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/semi_product_master_setting_create`}
        component={SemiProductMasterCreateRoot}
        menuFunction={'settings'}
      />

      <PCAuthRoute
        path={`${match.url}/settings/semi_product_master_setting_edit/:id`}
        component={SemiProductMasterEditRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/material_master_setting`}
        component={MaterialMasterSettingRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/material_master_setting_create`}
        component={MaterialMasterCreateRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/material_master_setting_edit/:id`}
        component={MaterialMasterEditRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/shipping_group_setting`}
        component={ShippingGroupMasterRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/shipping_group_setting_create`}
        component={ShippingGroupMasterCreateRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/shipping_group_setting_edit/:id`}
        component={ShippingGroupMasterEditRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/shipping_destination_setting`}
        component={ShippingDestinationMasterRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/shipping_destination_create`}
        component={ShippingDestinationMasterCreateRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/shipping_destination_edit/:id`}
        component={ShippingDestinationMasterEditRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/customer_setting`}
        component={CustomerMasterRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/customer_setting_create`}
        component={CustomerMasterCreateRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/customer_setting_edit/:id`}
        component={CustomerMasterEditRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/sales_store_setting`}
        component={SalesStoreMasterRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/sales_store_setting_create`}
        component={SalesStoreMasterCreateRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/sales_store_setting_edit/:id`}
        component={SalesStoreMasterEditRoot}
        menuFunction={'settings'}
      />

      <PCAuthRoute
        path={`${match.url}/settings/supplier_setting`}
        component={SupplierMasterRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/supplier_setting_create`}
        component={SupplierMasterCreateRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/supplier_setting_edit/:id`}
        component={SupplierMasterEditRoot}
        menuFunction={'settings'}
      />
      <PCAuthRoute
        path={`${match.url}/settings/manufacture_setting`}
        component={ManufactureEditRoot}
        menuFunction={'settings'}
      />

      <PCAuthRoute
        path={`${match.url}/product_production_result_pc`}
        component={ProductProductionResultPcRoot}
        menuFunction={'productProductionResult'}
      />
      <PCAuthRoute
        path={`${match.url}/semi_product_production_result_pc`}
        component={SemiProductProductionResultPcRoot}
        menuFunction={'productProductionResult'}
      />
      <PCAuthRoute
        path={`${match.url}/order_summary`}
        component={OrderSummaryRoot}
        menuFunction={'picking'}
      />
      <PCAuthRoute
        path={`${match.url}/picking`}
        component={PickingRoot}
        menuFunction={'picking'}
      />
      <PCAuthRoute
        path={`${match.url}/picking_detail/:id`}
        component={PickingDetailRoot}
        menuFunction={'picking'}
      />
      <PCAuthRoute
        path={`${match.url}/picking_by_shipping`}
        component={PickingByShippingRoot}
        menuFunction={'picking'}
      />
      <PCAuthRoute
        path={`${match.url}/picking_by_shipping_detail/:id`}
        component={PickingByShippingDetailRoot}
        menuFunction={'picking'}
      />
      <PCAuthRoute
        path={`${match.url}/shipping_status`}
        component={ShippingStatusRoot}
        menuFunction={'picking'}
      />
      <PCAuthRoute
        path={`${match.url}/accepting_orders`}
        component={AcceptingOrdersRoot}
        menuFunction={'picking'}
        exact
      />
      <PCAuthRoute
        path={`${match.url}/accepting_orders/:id/edit`}
        component={AcceptingOrdersEditRoot}
        menuFunction={'picking'}
        exact
      />
    </Switch>
  );
};
export default PCRoutes;
