import { useMutation } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { Todo } from '@lib/common/type';
import { InputParams } from '@lib/pc/sales_information/type';

const useUpdateSalesInformationMutation = (
  id: number,
  handleSuccess: () => void,
  setErrMsg: (errMsg: string) => void
) => {
  return useMutation(
    async (params: InputParams) => {
      return await axiosInstance().patch(
        `/api/v1/sales_informations/${id}`,
        humps.decamelizeKeys({
          sales_information: {
            customer_id: params.customerId,
            employee_id: params.employeeId,
            entry_date: params.entryDate,
            title: params.title,
            comment: params.comment,
            details_attributes: params.details,
          },
        })
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (error: Todo) => {
        setErrMsg(error.response.data.message);
      },
    }
  );
};

export default useUpdateSalesInformationMutation;
