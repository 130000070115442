import { Todo } from '@lib/common/type';

export type Employee = {
  code: string;
  id: number;
  name: string;
  email: string;
  roleIds: number[];
  lineAssignments: LineAssignments[];
};

export type LineAssignments = {
  factoryName: string;
  lineName: string;
};

export type EmployeesAndRoles = {
  code: string;
  id: number;
  name: string;
  email: string;
  roleIds: number[];

  employee: Employee;
  lineAssignments: LineAssignments[];
  rolesInfo: Todo;
};

// 更新箇所のtype
export type EmployeeUpdateParams = {
  code: string;
  id: number;
  name: string;
  email: string;
  roleIds: number[];
  lineAssignments: LineAssignments[];
};

// バックエンドからmodal画面(show)で取得するデータ
export type NewEmployeesAndRoles = {
  employee: Employee;
  rolesInfo: Todo;
};

export type RolesInfo = {
  roleId: number;
  role: string;
  customSupport: boolean;
};

// 一覧画面で使用
export type EmployeesInfo = {
  employeesAndRoles: EmployeesAndRoles[];
  rolesInfo: RolesInfo[];
  manufactureName: string;
};

// codeがnullとstring
export const initEmployeeData = {
  id: 0,
  name: '',
  code: null,
  email: '',
  roleIds: [0],
};

// 新規作成箇所のtype
export type EmployeeInputParams = {
  name: string | null;
  code: string | null;
  email: string | null;
};

export type ManufactureInfoType = {
  id: number;
  manufactureAddress1: string | null;
  manufactureAddress2: string | null;
  manufactureSealImage: string | null;
  manufactureName: string;
  manufacturePhoneNumber: string | null;
  manufactureZipCode: string | null;
};
