import { useQuery } from 'react-query';
import humps from 'humps';
import { axiosInstance } from '@lib/pc/common/api/axiosConfig';
import { SalesStoreEdit } from './type';

const useSalesStore = (id: string | number) => {
  const { data, refetch } = useQuery(['sales_stores', id], async () => {
    const data = await axiosInstance().get(
      `/api/v1/settings/sales_stores/${id}`
    );
    return data;
  });
  const salesStore = humps.camelizeKeys(
    data?.data
  ) as unknown as SalesStoreEdit;

  return { salesStore, refetch };
};

export default useSalesStore;
