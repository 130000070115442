import { useParams } from 'react-router-dom';
import SalesManagementFormModal from '@components/modules/pc/sales_management/SalesManagementFormModal/SalesManagementFormModal';
import useSalesInformation from '@lib/pc/sales_information/useSalesInformation';

export const SalesInformationEditRoot: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const { salesInformation, refetch } = useSalesInformation(id);

  return (
    <SalesManagementFormModal
      salesInformation={salesInformation}
      refetch={refetch}
    />
  );
};
